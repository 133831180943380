import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private readonly keycloakService: KeycloakService) {}

  redirectToLoginPage() {
    localStorage.clear();
    this.keycloakService.login();
  }

  get userName(): string {
    return this.keycloakService.getUsername();
  }

  // get userProfile(): any {
  //   return this.keycloakService.loadUserProfile()
  // }

  isLoggedIn(): boolean {
    return this.keycloakService.isLoggedIn();
  }

  logout(): void {
    localStorage.clear();
    this.keycloakService.logout(environment.keycloak.postLogoutRedirectUri);
  }
}
