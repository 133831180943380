<header class="header-conatiner border-b border-gray-200 bg-white">
    <div
        class="container-fluid mx-auto flex items-center justify-between px-6 py-3"
    >
        <!-- Logo and Text -->
        <div 
        class="flex items-center justify-center cursor-pointer" 
        (click)="navigateDashboard()"
        >
            <img src="../../../assets/logo/logo.svg" alt="" />
            <p class="header-logo text-lg font-extrabold text-gray-800">
                Pi Stream
            </p>
            <!-- Navigation Links -->
            <!-- <nav class="ms-6 flex">
                <a  [class.bg-gray-100]="activeRoute === 'dashboard'"
                    (click)="navigateDashboard()"
                    class="rounded-md px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
                    >Dashboard</a
                >
                <a
                    [class.bg-gray-100]="activeRoute === 'logs'"
                    (click)="navigateLogs()"
                    class="ms-4 rounded-md px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
                >Logs</a
                >
            </nav> -->
        </div>

        <!-- Middle Component -->
        <app-filter-header></app-filter-header>

        <!-- Right Side -->
        <div class="flex items-center space-x-4">
            <div class="flex items-center">
                <button 
                  (click)="toggleDesktopView(this.viewType[0])"
                  [disabled]="desktopView === 'Spatial View'"
                  [class.view-btn-active]="desktopView === this.viewType[0]"
                  class="flex gap-3 text-xs items-center view-btn bg-white border border-e-0 border-gray-300 rounded-tl-md rounded-bl-md px-4 py-2">
                  <img [src]="desktopView === this.viewType[0] ? '../../../assets/icons/cockpit_white.svg' : '../../../assets/icons/cockpit_gray.svg'" alt="cockpit">
                  {{this.viewType[0]}}
                </button>
                <button 
                [class.view-btn-active]="desktopView === this.viewType[1]"
                (click)="toggleDesktopView(this.viewType[1])"
                  class="flex gap-3 view-btn text-xs items-center bg-white border border-gray-300 rounded-tr-md rounded-br-md px-4 py-2">
                  <img [src]="desktopView !== this.viewType[1] ? '../../../assets/icons/birdseye.svg' : '../../../assets/icons/birdseye_gray.svg'" alt="bird's eye">
                  {{this.viewType[1]}}
                </button>
          
              </div>
            <button
                class="flex items-center rounded-lg border border-gray-300 bg-white px-4 py-2 hover:bg-gray-100"
                (click)="toggleFullScreen()"
            >
                <img
                    src="../../../assets/icons/fullscreen.svg"
                    alt="fill screen"
                />
                <!-- <span class="ml-2">{{!isFullScreenMode ? 'Full Screen' : 'Minimize'}}</span> -->
            </button>
            <!-- <button
                class="flex items-center bg-white px-3 py-2 hover:bg-gray-100"
            >
                <img src="../../../assets/icons/settings.svg" alt="settings" />
            </button>
            <button
                class="flex items-center bg-white px-3 py-2 hover:bg-gray-100"
            >
                <img src="../../../assets/icons/bell.svg" alt="bell" />
            </button> -->
            <!-- Circle Profile -->
            <div class="relative">
                <button
                    (click)="handlepopUp()"
                    class="flex h-10 w-10 items-center overflow-hidden rounded-full border border-gray-300 bg-white"
                >
                    <img
                        class="h-full w-full object-cover"
                        src="../../../assets/icons/Avatar.png"
                        alt="Profile Picture"
                    />
                </button>
                <!-- Online Indicator -->
                <!-- <div
                    class="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-green-400"
                ></div> -->
                <!-- dropdown -->
                @if (isOpen) {
                <div
                    class="absolute right-0 z-10 mt-2 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                >
                    <!-- Profile Section -->
                    <div class="flex items-center px-4 py-3">
                        <img
                            class="mr-3 h-8 w-8 rounded-full"
                            src="../../../assets/icons/Avatar.png"
                            alt="Profile Picture"
                        />
                        <div>
                            <p class="font-semibold text-gray-800">
                                {{authenticationService.userName}}
                            </p>
                            <!-- <p class="text-gray-600">Test.example.com</p> -->
                        </div>
                    </div>
                    <!-- Divider -->
                    <hr class="my-1 border-gray-200" />
                    <!-- Section 1 -->
                    <!-- <div class="mt-3 px-4">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center">
                                <img
                                    class="mr-2 h-5 w-5"
                                    src="../../../assets/icons/person.svg"
                                    alt="Icon 1"
                                />
                                <span class="text-gray-600">View Profile</span>
                            </div>
                            <span class="text-gray-500">⌘k → P</span>
                        </div>
                        <div class="my-4 flex items-center justify-between">
                            <div class="flex items-center">
                                <img
                                    class="mr-2 h-5 w-5"
                                    src="../../../assets/icons/settings.svg"
                                    alt="Icon 1"
                                />
                                <span class="text-gray-600">Settings</span>
                            </div>
                            <span class="text-gray-500">⌘ S</span>
                        </div>
                    </div> -->

                    <!-- Section 2 -->
                    <!-- <div class="px-4">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center">
                                <img
                                    class="mr-2 h-5 w-5"
                                    src="../../../assets/icons/team.svg"
                                    alt="Icon 1"
                                />
                                <span class="text-gray-600">Team</span>
                            </div>
                            <span class="text-gray-500">⌘k → T</span>
                        </div>
                    </div> -->
                    <button (click)="authenticationService.logout()" class="px-4 py-3">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center">
                                <img
                                    class="mr-2 h-5 w-5"
                                    src="../../../assets/icons/logout.svg"
                                    alt="Icon 1"
                                />
                                <span class="text-gray-600">Log out</span>
                            </div>
                            <!-- <span class="text-gray-500">⌥⇧Q</span> -->
                        </div>
                    </button>
                </div>
                }
            </div>
        </div>
    </div>
</header>
